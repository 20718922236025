.layout_container {
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 1480px;
  margin: 0 auto;
}

.layout_block {
  display: flex;
  width: 100%;
  flex-direction: row;
  margin: 27px 40px;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 1225px) {
    margin: 16px 40px;
  }

  @media screen and (max-width: 725px) {
    margin: 16px 16px;
  }

  @media screen and (max-width: 680px) {
    flex-wrap: wrap;
    margin: 16px 16px;
  }
}

.logo {
  @media screen and (min-width: 1225px) and (max-width: 1710px) {
    svg {
      width: 208px;
      height: 75px;
    }
  }

  @media screen and (max-width: 1225px) {
    svg {
      width: 208px;
      height: 75px;
    }
  }

  @media screen and (max-width: 680px) {
    width: 100%;

    svg {
      width: 200px;
      height: 75px;
    }
  }

  @media screen and (max-width: 470px) {
    svg {
      width: 138px;
      height: 50px;
    }
  }
}

.menu_item {
  display: none;

  @media screen and (max-width: 1225px) {
    display: flex;
    width: 74px;
    height: 74px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 16px;
    background: #eeeeee;
  }

/*   @media screen and (max-width: 680px) {
    margin-top: 8px;
  } */

  @media screen and (max-width: 470px) {
    width: 44px;
    height: 44px;
    border-radius: 8px;
  }
}

.links_block {
  display: flex;
  flex-direction: row;
  gap: 3px;

  @media screen and (max-width: 1225px) {
    display: none;
  }

  .active_link {
    background: #eeeeee;
    border-radius: 12px;
  }
}

.link:hover {
  background: #eeeeee;
  border-radius: 12px;
}

.link {
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #212121;
  font-size: var(--font-size-xl);
  font-weight: var(--font-weight-bold);
  text-transform: uppercase;

  @media screen and (min-width: 1225px) and (max-width: 1710px) {
    font-size: var(--font-size-m);
    padding-top: 15px;
    padding-bottom: 15px;
  }

  @media screen and (max-width: 1710px) {
    padding-left: 25px;
    padding-right: 25px;
  }

  a {
    color: #212121;
    text-decoration: none;
  }
}

.yellow_blocks {
  display: flex;
  gap: 10px;

/*   @media screen and (max-width: 680px) {
    padding-top: 8px;
  } */
}

.yellow_block_first {
  display: flex;
  background: var(--theme-color);
  padding: 0 24px;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  height: 74px;

  @media screen and (min-width: 1225px) and (max-width: 1710px) {
    height: 59px;
  }

  @media screen and (max-width: 470px) {
    height: 44px;
    padding: 16px;
    border-radius: 8px;
  }


  a {
    font-size: var(--font-size-xxl);
    font-weight: var(--font-weight-bold);
    color: #212121;
    text-decoration: none;

    @media screen and (min-width: 1225px) and (max-width: 1710px) {
      font-size: var(--font-size-mxl);
    }

    @media screen and (max-width: 470px) {
      font-size: var(--font-size-sxl);
    }
  }

  .phone_icon {
    @media screen and (min-width: 1225px) and (max-width: 1710px) {
      svg {
        width: 35px;
        height: 35px;
        margin-top: 4px;
      }
    }

    @media screen and (max-width: 470px) {
      svg {
        width: 24px;
        height: 24px;
        margin-top: 4px;
      }
    }
  }
}

.options {
  display: flex;
  align-items: center;
  justify-content: center;
}

.yellow_block_second {
  display: flex;
  background: var(--theme-color);
  padding: 0 24px;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  height: 74px;

  @media screen and (min-width: 1225px) and (max-width: 1710px) {
    padding: 0 16px;
    height: 59px;
  }

  @media screen and (min-width: 1225px) and (max-width: 1710px) {
    .app_store_icon {
      svg {
        width: 26px;
        height: 26px;
      }
    }

    .play_market_icon {
      svg {
        width: 26px;
        height: 26px;
      }
    }
  }

  @media screen and (max-width: 470px) {
    height: 44px;
    padding: 0 21px;
    border-radius: 8px;

    .app_store_icon {
      svg {
        width: 22px;
        height: 22px;
      }
    }

    .play_market_icon {
      svg {
        width: 22px;
        height: 22px;
      }
    }
  }

  .line_icon {
    padding-left: 16px;
    padding-right: 16px;

    @media screen and (min-width: 1225px) and (max-width: 1710px) {
      padding-left: 12px;
      padding-right: 12px;

      svg {
        height: 18px;
      }
    }

    @media screen and (max-width: 470px) {
      padding-left: 12px;
      padding-right: 12px;
      margin-top: 2px;

      svg {
        height: 18px;
      }
    }
  }
}

.select_wrapper {
  padding-right: 40px;

  @media screen and (max-width: 1225px) {
    display: none;
  }
}

.custom_select {
  position: relative;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  user-select: none;
  background-color: transparent;
  border: none;
  outline: none;
  color: #212121;
  font-size: var(--font-size-xl);
  font-weight: var(--font-weight-bold);
  text-transform: uppercase;
  text-decoration: underline;
  text-underline-offset: 4px;

  @media screen and (min-width: 1225px) and (max-width: 1710px) {
    font-size: var(--font-size-m);
  }
}

.custom_select span {
  margin-right: 4px;

  @media screen and (min-width: 1225px) and (max-width: 1710px) {
    margin-right: 1px;
  }
}

.arrow_down_icon {
  margin-top: 2px;

  @media screen and (min-width: 1225px) and (max-width: 1710px) {
    margin-top: 1px;

    svg {
      height: 18px;
    }
  }
}

.custom_select .dropdown_arrow {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #212121;
  margin-left: 5px;
}

.opened .options_list {
  display: block;
}

.options_list {
  display: none;
  position: absolute;
  top: 100%;
  left: -11px;
  background-color: white;
  border: 1px solid #ccc;
  border-top: none;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1;
}

.option {
  padding: 5px 10px;
  cursor: pointer;

  &:hover {
    background-color: #f5f5f5;
  }
}

.mobile_menu_open {
  right: 0 !important;
  z-index: 9999;
}

.mobile_menu {
  display: none;
  z-index: 9999;

  @media screen and (max-width: 1225px) {
    position: fixed;
    display: flex;
    transition: right 0.3s ease-in-out;
    flex-direction: column;
    right: -100%;
    background: white;
    top: 136px;
    width: 70%;
    height: 100%;
    gap: 10px;
    padding: 15px;

    @media screen and (min-width: 680px) and (max-width: 1225px) {
      top: 110px;
      width: 40%;
    }

    @media screen and (min-width: 470px) and (max-width: 680px) {
      top: 190px;
    }

    .mobile_active_link {
      background: #eeeeee;
      border-radius: 12px;

      a {
        font-size: var(--font-size-xl);
        font-weight: var(--font-weight-bold);
        text-decoration: none;
        padding-left: 24px;
        text-transform: uppercase;
      }
    }

    .mobile_link {
      display: flex;
      align-items: center;
      height: 50px;

      a {
        color: #212121;
        font-size: var(--font-size-xl);
        font-weight: var(--font-weight-bold);
        text-decoration: none;
        padding-left: 24px;
        text-transform: uppercase;
      }
    }

    .select_wrapper_mobile {
      display: flex;
      padding: 11px 0;
      align-items: center;
      justify-content: center;
      border-radius: 12px;
      width: 100%;
      padding-bottom: 30px;
    }

    .custom_select_mobile {
      position: relative;
      cursor: pointer;
      display: inline-flex;
      align-items: center;
      user-select: none;
      background-color: transparent;
      border: none;
      outline: none;
      color: #212121;
      font-size: var(--font-size-xl);
      font-weight: var(--font-weight-bold);
      text-transform: uppercase;
      text-decoration: underline;
      text-underline-offset: 4px;
    }

    .custom_select_mobile span {
      margin-right: 1px;
    }

    .arrow_down_icon_mobile {
      margin-top: 1px;

      svg {
        height: 18px;
      }
    }

    .custom_select_mobile .dropdown_arrow_mobile {
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid #212121;
      margin-left: 5px;
    }

    .opened_mobile .options_list_mobile {
      display: block;
    }

    .options_list_mobile {
      display: none;
      position: absolute;
      top: 100%;
      left: -11px;
      background-color: white;
      border: 1px solid #ccc;
      border-top: none;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
      z-index: 1;
    }

    .option_mobile {
      padding: 5px 10px;
      cursor: pointer;

      &:hover {
        background-color: #f5f5f5;
      }
    }
  }
}
