:root {
    // --theme-color: #b0c1b6;
    --theme-color: #FEC600;
}

html[data-theme="green"] {
    --theme-color: #20CF6C;
    // --theme-color: #FFDE17;
}
html[data-theme="yellow"] {
    --theme-color: #20CF6C;
    // --theme-color: #FFDE17;
}