.hang_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-self: center;
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 20px;


    @media screen and (max-width: 1225px) {
        padding-left: 0;
        padding-right: 0;
    }

    @media screen and (max-width: 1000px) {
        padding-bottom: 156px;
    }

    @media screen and (max-width: 660px) {
        padding-bottom: 100px;
    }
}

.main_text_4 {
    display: flex;
    color: #000;
    font-size: 40px;
    font-weight: 700;
    padding-bottom: 64px;
    line-height: normal;
    align-items: center;
    text-align: center;

    @media screen and (min-width: 1225px) and (max-width: 1710px) {
        font-size: 28px;
    }

    @media screen and (min-width: 1225px) and (max-width: 1435px) {
        font-size: 28px;
    }

    @media screen and (max-width: 1225px) {
        font-size: 28px;
    }

    @media screen and (max-width: 1000px) {
        font-size: 40px;
        font-weight: 700;
    }

    @media screen and (max-width: 470px) {
        font-size: 24px;
    }
}

.hang_block {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;

    @media screen and (max-width: 1000px) {
        flex-direction: column;
        gap: 24px;
    }
}

.item_block {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
    width: 500px;

    @media screen and (max-width: 1710px) {
        width: 370px;
    }

    @media screen and (max-width: 1000px) {
        width: auto;
    }
}

.icon_block {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 96px;
    height: 96px;
    border-radius: 100%;
    background: #000;

    @media screen and (min-width: 769px) and (max-width: 1710px) {
        width: 67px;
        height: 67px;

        img {
            height: 32px;
        }
    }

    @media screen and (max-width: 1000px) {
        width: 96px;
        height: 96px;

        img {
            height: auto;
        }
    }
}

.text_block {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.main_text {
    font-size: 24px;
    font-weight: 700;
    line-height: normal;
    padding-top: 20px;

    @media screen and (min-width: 769px) and (max-width: 1710px) {
        font-size: 15px;
    }

    @media screen and (max-width: 1000px) {
        font-size: 24px;
    }
}

.second_text {
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-top: 10px;

    @media screen and (min-width: 769px) and (max-width: 1710px) {
        font-size: 15px;
    }

    @media screen and (max-width: 1000px) {
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    @media screen and (max-width: 670px) {
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}