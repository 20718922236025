.media_container {
    display: flex;
    flex-direction: row;
    gap: 24px;
    padding-bottom: 210px;

    @media screen and (max-width: 1000px) {
        flex-direction: column;
    }

    @media screen and (max-width: 1000px) {
        padding-bottom: 150px;
    }

    @media screen and (max-width: 560px) {
        padding-bottom: 86px;
    }

    // @media screen and (max-width: 570px) {
    //     padding-top: 40px;
    //     text-align: center;
    //     align-items: center;
    //     padding-bottom: 76px;
    // }
}

.first_block {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    border-radius: 64px;
    background: #FEC600;
    width: 62%;
    padding-top: 90px;

    @media screen and (min-width: 800px) and (max-width: 1710px) {
        padding-top: 60px;
    }

    @media screen and (min-width: 800px) and (max-width: 1120px) {
        width: 72%;
    }

    @media screen and (max-width: 1000px) {
        width: 100%;
    }

    @media screen and (max-width: 800px) {
        align-items: flex-start;
        justify-content: flex-start;
    }

    @media screen and (max-width: 570px) {
        border-radius: 40px;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding-top: 60px;
    }

    .main_text {
        color: #FFF;
        font-size: 64px;
        font-weight: var(--font-weight-bold);
        line-height: normal;
        letter-spacing: 0.1px;
        padding-top: 24px;
        max-width: 1000px;

        @media screen and (min-width: 800px) and (max-width: 1710px) {
            font-size: 46px;
            max-width: 700px;
        }

        @media screen and (min-width: 545px) and (max-width: 800px) {
            font-size: 46px;
            max-width: none;
            align-items: center;
            justify-content: center;
            text-align: center;
        }

        @media screen and (min-width: 570px) and (max-width: 545px) {
            font-size: 36px;
        }

        @media screen and (max-width: 570px) {
            font-size: 24px;
            padding-top: 0;
            max-width: none;
            padding-left: 16px;
            padding-right: 16px;
        }
    }

    .media_block {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding-top: 80px;
        padding-bottom: 100px;
        gap: 60px;

        @media screen and (min-width: 800px) and (max-width: 1710px) {
            padding-top: 70px;
            padding-bottom: 70px;
        }

        @media screen and (min-width: 800px) and (max-width: 1190px) {
            gap: 0;
        }

        @media screen and (max-width: 800px) {
            flex-direction: column;
            align-items: flex-start;
            padding-left: 56px;
            padding-top: 40px;
        }

        @media screen and (max-width: 640px) {
            padding-left: 30px;
        }

        @media screen and (max-width: 570px) {
            align-items: center;
            justify-content: center;
            text-align: center;
            gap: 24px;
            padding-top: 24px;
            padding-left: 16px;
            padding-right: 16px;
            padding-bottom: 60px;
        }
    }

    .row_item {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        gap: 60px;

        .other_item {
            display: none;
        }

        .another_item {
            display: flex;
        }

        @media screen and (min-width: 800px) and (max-width: 1710px) {
            gap: 50px;
        }

        @media screen and (max-width: 800px) {
            .other_item {
                display: flex;
            }

            .another_item {
                display: none;
            }
        }

        @media screen and (max-width: 570px) {
            align-items: center;
            justify-content: center;
            text-align: center;
            padding-left: 0;
            gap: 24px;
        }
    }

    .one_item {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;

        @media screen and (max-width: 570px) {
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            padding: 0;
        }
    }

    .icon_item {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 96px;
        height: 96px;
        border-radius: 100%;
        background: var(--theme-color);

        @media screen and (min-width: 800px) and (max-width: 1710px) {
            width: 67px;
            height: 67px;

            img {
                height: 35px;
            }
        }

        @media screen and (max-width: 1000px) {
            width: 96px;
            height: 96px;

            img {
                height: auto;
            }
        }
    }

    .text_item {
        font-size: 32px;
        color: #FFF;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 0.1px;
        padding-left: 24px;
        text-align: start;

        @media screen and (min-width: 800px) and (max-width: 1710px) {
            font-size: 23px;
        }

        @media screen and (min-width: 800px) and (max-width: 1190px) {
            max-width: 200px;
        }

        @media screen and (max-width: 570px) {
            font-size: 24px;
            max-width: none;
            padding: 0;
            padding-top: 24px;
            align-items: center;
            justify-content: center;
            text-align: center;
        }
    }

    .last_item {
        width: 200px;

        @media screen and (max-width: 800px) {
            width: auto;
        }

        @media screen and (max-width: 560px) {
            align-items: center;
            justify-content: center;
            text-align: center;
        }
    }
}

.second_block {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 64px;
    background: var(--theme-color);
    width: 38%;

    @media screen and (min-width: 800px) and (max-width: 1120px) {
        width: 28%;
    }

    @media screen and (max-width: 1000px) {
        width: 100%;
        padding-top: 70px;
        padding-bottom: 70px;
    }

    @media screen and (max-width: 570px) {
        border-radius: 40px;
        padding-top: 50px;
        padding-bottom: 50px;
    }

    a {
        width: 275px;

        @media screen and (min-width: 800px) and (max-width: 1710px) {
            width: 220px;
        }

        @media screen and (max-width: 570px) {
            width: calc(100% - 32px);
        }

        img {
            width: 100%;
        }
    }
}

.second_icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 96px;
    height: 96px;
    border-radius: 100%;
    background: #FFF;

    @media screen and (min-width: 800px) and (max-width: 1710px) {
        width: 67px;
        height: 67px;

        img {
            height: 35px;
        }
    }

    @media screen and (max-width: 1000px) {
        width: 96px;
        height: 96px;

        img {
            height: auto;
        }
    }
}

.text_global {
    font-size: 48px;
    font-weight: 700;
    color: #212121;
    line-height: normal;
    letter-spacing: 0.5px;
    text-align: center;
    padding-top: 40px;
    padding-bottom: 40px;

    @media screen and (min-width: 800px) and (max-width: 1710px) {
        font-size: 36px;
        padding-top: 30px;
        padding-bottom: 30px;
    }

    @media screen and (max-width: 570px) {
        font-size: 24px;
        padding-top: 16px;
        padding-bottom: 16px;
    }
}