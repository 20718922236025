.pairs_container {
    display: flex;
    flex-direction: column;
    padding-bottom: 170px;

    @media screen and (min-width: 470px) and (max-width: 1225px) {
        padding-bottom: 85px;
    }

    @media screen and (max-width: 470px) {
        padding-bottom: 60px;
    }

    a {
        text-decoration: none;
        color: #FFF;
        font-size: 20px;
        font-weight: 700;
        letter-spacing: 0.6px;
        text-transform: uppercase;

        @media screen and (min-width: 1000px) and (max-width: 1710px) {
            font-size: 15px;
        }

        @media screen and (max-width: 530px) {
            letter-spacing: 1px;
            font-weight: 700;
        }
    }
}

.main_block {
    display: flex;
    position: relative;
    flex-direction: row;
    min-height: 720px;
    background: #FEC600;
    border-radius: 64px;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 56px;

    @media screen and (min-width: 1370px) and (max-width: 1710px) {
        min-height: 570px;
    }

    @media screen and (min-width: 1225px) and (max-width: 1370px) {
        min-height: 500px;
    }

    @media screen and (min-width: 680px) and (max-width: 1225px) {
        min-height: 570px;
        border-radius: 64px;
        padding-bottom: 85px;
    }

    @media screen and (min-width: 470px) and (max-width: 769px) {
        padding: 56px 42px;
        min-height: auto;
    }

    @media screen and (max-width: 680px) {
        min-height: 800px;
        flex-direction: column;
        border-radius: 40px;
        min-height: auto;
    }

    @media screen and (max-width: 470px) {
        min-height: 600px;
        flex-direction: column;
        border-radius: 40px;
        padding: 16px;
        min-height: auto;
        padding-bottom: 50px;
    }
}

.icon_main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.pairs_item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 96px;
    height: 96px;
    background: var(--theme-color);
    border-radius: 100%;

    @media screen and (min-width: 1000px) and (max-width: 1710px) {
        width: 67px;
        height: 67px;

        img {
            width: 37px;
            height: 37px;
        }
    }

    @media screen and (min-width: 1000px) and (max-width: 1225px) {
        width: 57px;
        height: 57px;

        img {
            width: 27px;
            height: 27px;
        }
    }
}

.main_text_2 {
    color: #FFF;
    font-size: 64px;
    font-weight: var(--font-weight-bold);
    line-height: normal;
    letter-spacing: 0.1px;
    padding-top: 24px;
    max-width: 1000px;

    @media screen and (min-width: 830px) and (max-width: 1710px) {
        font-size: 46px;
        max-width: 700px;
    }

    @media screen and (min-width: 545px) and (max-width: 830px) {
        font-size: 46px;
    }

    @media screen and (min-width: 470px) and (max-width: 545px) {
        font-size: 36px;
    }

    @media screen and (max-width: 470px) {
        font-size: 24px;
    }
}

.main_text_3 {
    display: flex;
    color: var(--white-day, #FFF);
    font-size: 40px;
    font-weight: var(--font-weight-regular);
    line-height: normal;
    align-items: center;
    justify-content: center;
    letter-spacing: 0.1px;
    padding-top: 24px;
    padding-bottom: 80px;

    @media screen and (min-width: 830px) and (max-width: 1710px) {
        font-size: 29px;
        padding-bottom: 80px;
    }

    @media screen and (min-width: 545px) and (max-width: 830px) {
        font-size: 40px;
        padding-top: 24px;
        padding-bottom: 24px;
    }

    @media screen and (min-width: 470px) and (max-width: 545px) {
        font-size: 32px;
        padding-top: 10px;
        padding-bottom: 24px;
    }

    @media screen and (max-width: 470px) {
        font-size: 24px;
        padding-top: 24px;
        padding-bottom: 24px;
    }
}

.main_btn {
    display: flex;
    width: 300px;
    height: 74px;
    padding: 2px 0px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    background: #212121;

    @media screen and (min-width: 1000px) and (max-width: 1710px) {
        font-size: 62px;
        max-width: 650px;
        width: 230px;
        height: 60px;
    }

    @media screen and (max-width: 530px) {
        width: 100%;
        max-width: none;
        height: 74px;
        letter-spacing: 1px;
    }
}